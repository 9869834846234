const jwt = require('jwt-simple');

export function tokenAuth() {
    if (typeof window.BX24 === 'object' && window.BX24 !== null) {
        return userCurrent();
    }

    return develop();
}

function userCurrent() {
    let token = null;
    const secretOrPrivateKey = 'Phh9tzx8YpFRQjXMkZRR7k0lAWAjB/j8RcmBbFIMTVVkuzzrHGuvfJw4QMOTeSCSXZAWqEyHQV02jm5MjGCVqal60OD867dUtrBjQ+hEVjXUuctmAe4Ox2nNvtkLnFnxzBZDP+P4+8DJ5RzRNtg49Xb56dX8egI+Uakh88x2+pRlXw/LGlMWokW1G3FA23kPQ5DBB/1kdmDMuwV6gc9LwhI7NNTfGyGpOQzSGwooIVQtPZya+07dzHyHtdmU9sm20UEtlUnpf4BVcMG8n01psGCb58lZLpE7Xq2Xk5wgz8eInlfM2wPFStQl0XUeIEfcavUU5c4UUBaoHwoFSxlF0Q==';

    return new Promise((resolve) => {
        window.BX24.callMethod('user.current', {}, (result) => {
            if (result.error()) {
                console.error(result.error());
            } else {
                console.info("User Current " + result.data());

                const payload = {
                    idPresupuesto: null,
                    idOportunidadBitrix: "",
                    usuario: result.data()?.UF_USR_USUARIO_KARVE,
                    pass: result.data()?.UF_USR_DEERE_KARVE,
                    oficina: result.data()?.UF_USR_OFICINA_KARVE,
                    exp: Math.floor(Date.now() / 1000) + 3600,
                    id: null
                };

                try {
                    token = jwt.encode(payload, secretOrPrivateKey, 'HS256');

                } catch (error) {
                    console.log('Error signing token:', error);
                }

                resolve(token);
            }
        });
    });
}

function develop() {
    let token = null;
    let secretOrPrivateKey = 'Phh9tzx8YpFRQjXMkZRR7k0lAWAjB/j8RcmBbFIMTVVkuzzrHGuvfJw4QMOTeSCSXZAWqEyHQV02jm5MjGCVqal60OD867dUtrBjQ+hEVjXUuctmAe4Ox2nNvtkLnFnxzBZDP+P4+8DJ5RzRNtg49Xb56dX8egI+Uakh88x2+pRlXw/LGlMWokW1G3FA23kPQ5DBB/1kdmDMuwV6gc9LwhI7NNTfGyGpOQzSGwooIVQtPZya+07dzHyHtdmU9sm20UEtlUnpf4BVcMG8n01psGCb58lZLpE7Xq2Xk5wgz8eInlfM2wPFStQl0XUeIEfcavUU5c4UUBaoHwoFSxlF0Q==';


    const payload = {
        idPresupuesto: null,
        idOportunidadBitrix: "",
        usuario: 'test',
        pass: 'test',
        oficina: '00',
        exp: Math.floor(Date.now() / 1000) + 3600,
        id: null
    };

    try {
        token = jwt.encode(payload, secretOrPrivateKey, 'HS256');

    } catch (error) {
        console.log('Error signing token:', error);
    }

    return new Promise((resolve) => {
        resolve(token);
    });
}
